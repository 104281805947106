import React from 'react';
import { GeneralEntity } from "myfitworld-model";
import EntityManager from "../../../entity/EntityManager";
import GeneralEntityListItem from "./GeneralEntityListItem";
import GeneralEntityForm from "./GeneralEntityForm";
import { ApiInterface } from "../../../api/dataInterfaceFactory";

const GeneralEntityFactory = (
  api: ApiInterface<GeneralEntity>,
  entityName: string,
  IndexName: string,
  locale: string // Pass intl as an argument
) => () => (
    <EntityManager<GeneralEntity>
      entityName={entityName}
      apiInterface={api}
      ListItemComponent={GeneralEntityListItem}
      FormComponent={GeneralEntityForm}
      emptyStateTitle={`No registered items, start by adding a ${entityName}.`}
      sortKey={`name.${locale}`}
      listItemHeight={62}
      IndexName={IndexName}
    />
);

export default GeneralEntityFactory;
