export const availableLocales = [
    'en',
    'sr',
    'hr',
    'de',
    'es',
    'ro',
    'ru',
    'bg',
    'pl',
];