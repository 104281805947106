import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import {Typography, Box, Grid} from '@material-ui/core';
import {Alert} from '@material-ui/lab';
import {FormProvider, useForm} from 'react-hook-form';
import {Exercise, GeneralEntity, Grocery, Resource} from "myfitworld-model";
import LanguageSelect from "../components/language/LanguageSelect";
import {UnpackNestedValue, UseFormMethods} from "react-hook-form/dist/types/form";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import SimpleDialog from '../components/SimpleDialog';
import {createNewButtonLabel} from "./EntityManager"
import globalState, { GlobalState } from '../globalState';
import { Equipment } from 'myfitworld-model/src';
import buildKeyToNameMap from '../utils/buildKeyToNameMap';
import useEntityList from '../hooks/useEntityList';
import { counterProductiveForApi, goodForApi } from '../api/common';
import { useMfwThemeProvider } from 'myfitworld-utils';
import { ModeFormType } from '../types/other';
import { useIntl } from 'react-intl';
import entityMessages from './messages';
import globalMessages from '../messages';

export interface EntityFormProps<T> {
  entityName: string,
  entityCreateHelper?: string,
  handleClose: () => void,
  handleCreate?: (data: UnpackNestedValue<T>) => Promise<void>,
  handleUpdate?: (data: UnpackNestedValue<T>) => Promise<void>,
  defaultState: T,
  mode: ModeFormType,
  loading: boolean,
  formErrorMessage?: string,
  multiLang?: boolean,
  children: (props: UseFormMethods<T>) => any,
  disabled?: boolean,
  IndexName?: any,
  setDefaultState?: any
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sticky: {
      position: 'sticky',
      bottom: 0,
      backgroundColor: theme.palette.background.default,
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      zIndex: 1,
    },
    right: {
      position: 'absolute',
      right: 0,
    }
  }),
);

function EntityForm<T extends Resource>({
  entityName,
  entityCreateHelper,
  handleClose,
  handleCreate,
  handleUpdate,
  defaultState,
  mode,
  loading,
  formErrorMessage,
  multiLang = true,
  children,
  disabled,
  IndexName,
}: EntityFormProps<T>) {
  const classes = useStyles();
  const {formatMessage, locale} = useIntl();

  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const {theme} = useMfwThemeProvider();

  const formProps = useForm<T>(
    (mode === 'Edit' && defaultState !== undefined ?
      {defaultValues: defaultState} :
      {defaultValues: {}}) as any
  );
  
  const {data: goodForOptions } = useEntityList<GeneralEntity>(goodForApi.list);
  const {data: counterProductiveForOptions } = useEntityList<GeneralEntity>(counterProductiveForApi.list);
  const checkGoodVSCounterProductive = (goodForIds?: Array<string>, counterProductiveForIds?: Array<string>) =>{
    if(goodForIds && counterProductiveForIds ){
      const allCounter: Record<string, string> = buildKeyToNameMap(locale, counterProductiveForOptions);
      const allGoodFor: Record<string, string> = buildKeyToNameMap(locale, goodForOptions);
      const finalGood = (goodForIds || []).map(item => allGoodFor[item]);
      const finalCounterProductive = (counterProductiveForIds || []).map(item => allCounter[item]);
      const common = finalGood.filter(g =>
      finalCounterProductive.includes(g)
      );
      if(common.length>0){
        return true;
      }
    }
    return false;
  }

  useEffect(() => {
    if (defaultState !== undefined) {
      formProps.reset(defaultState as any)
    } else {
      formProps.reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultState]);

  const onSubmit = async (data: UnpackNestedValue<T>) => {
    if (data) {
      if(((data as unknown as Equipment).name && (data as unknown as Equipment).name.en === "") || ((entityName === 'Exercise' || entityName === 'Grocery') && (data as unknown as (Exercise | Grocery)).title.en === "")){

        globalState.update((state: GlobalState) => {
          state.toastQueue.push({
            message: formatMessage(entityMessages.please_enter_the_title_of_the_exercise_in_english),
            severity: "error"
          });
        });
      } else if (!(data as unknown as Exercise).videoURL && !(data as unknown as Exercise).videoLink && entityName === 'Exercise') {
          globalState.update((state: GlobalState) => {
            state.toastQueue.push({
              message: formatMessage(entityMessages.you_have_to_upload_video_file_or_youtube_link),
              severity: "error"
            });
          });
      } else if(checkGoodVSCounterProductive((data as unknown as Grocery).goodFor, (data as unknown as Grocery).counterProductiveFor)){
        globalState.update((state: GlobalState) => {
          state.toastQueue.push({
            message:  formatMessage(globalMessages.good_for_and_counter_productive_for_can_not_have_the_same_values),
            severity: "error"
          });
        });
      } else  {

        if (mode === 'Edit') {
          let newData:any = {...data};
          if(entityName === 'Grocery'){
            delete newData.nutritionValues;
            delete newData.additionalNutritionValues;
          }
          handleUpdate && handleUpdate({...defaultState, ...newData} as UnpackNestedValue<T>);
        } else if (mode === 'Create') {
          if(entityName === 'Exercise' || entityName === 'Workout'){
            data = {
              ...data,
              isWhiteLabel: theme.isWhiteLabel,
              publicDate: new Date(),
            };
          }
          handleCreate && handleCreate(data);
        }
    }}
  };

  const editModeLabel = (IndexName: string) => {
    if(IndexName === "exercise"){
      return "edit_exercise"
    }else if (IndexName === "equipment") {
      return "edit_equipment"
    } else if (IndexName === "fitness"){
      return "edit_activity"
    } else if (IndexName === "muscle"){
      return "edit_muscle_group"
    } else if (IndexName === "goals"){
      return "edit_goal"
    } else if (IndexName === "motor"){
      return "edit_motor_skill"
    } else if (IndexName === "sport"){
      return "edit_sport"
    } else if (IndexName === "health"){
      return "edit_health_issue"
    } else if (IndexName === "expertise"){
      return "edit_expertise"
    } else if (IndexName === "grocery"){
      return "edit_grocery"
    } else if (IndexName === "tutorial") {
      return "edit_tutorial"
    }
      return "edit_duration"
  }

  return (
    <Box style={{flex: 1}}>
      <Box mb={2}>
        <Typography variant='h4' gutterBottom>
        {mode === 'Edit' ? formatMessage((entityMessages as any)[editModeLabel(IndexName)]) : formatMessage((entityMessages as any)[createNewButtonLabel(IndexName)])}
        </Typography>
        {
          mode === 'Create' && entityCreateHelper &&
          <Typography variant='body1' color="textSecondary">
            {entityCreateHelper}
          </Typography>
        }

        <Box my={2}>
          {multiLang && <LanguageSelect<T> watch={formProps.watch}/>}
        </Box>

        <FormProvider {...formProps}>
          <form>
            {children({...formProps})}
          </form>
        </FormProvider>
      </Box>

      <Box className={classes.sticky}>
        <Grid container spacing={2}>
          <Grid item>
            <Button
              color='primary' variant={formProps.formState.isDirty ? 'contained' : 'outlined'}
              onClick={formProps.handleSubmit(onSubmit)}
              disabled={loading || disabled}
            >
              {mode === 'Edit' ? formatMessage(globalMessages.update) : formatMessage(globalMessages.create)}
            </Button>
          </Grid>

          <Grid item>
            <Button color='secondary' onClick={handleClose} disabled={loading}>
            {formatMessage(globalMessages.cancel)}
            </Button>
          </Grid>
          {
            mode === 'Edit' && entityName === 'Grocery' &&
            <Grid item>
              {
                (defaultState as any)?.archived &&
                <Button variant="outlined" onClick={() => setIsOpenDialog(true)} disabled={disabled} className={classes.right}>
                  {formatMessage(globalMessages.unarchive)}
                </Button>
              }

              {
                (!(defaultState as any)?.archived || (defaultState as any)?.archived === undefined) &&
                <Button variant="outlined" onClick={() => setIsOpenDialog(true)} disabled={disabled} className={classes.right}>
                  {formatMessage(globalMessages.archive)}
                </Button>
              }
              <SimpleDialog
                title={(defaultState as any)?.archived ?
                  formatMessage(entityMessages.unarchive_grocery) : formatMessage(entityMessages.archive_grocery)
                }
                text={(defaultState as any)?.archived ?
                 formatMessage(entityMessages.unarchive_grocery_question) : formatMessage(entityMessages.archive_grocery_question)
                }
                open={isOpenDialog}
                onClose={() => setIsOpenDialog(false)}
                onConfirm={() => {
                  setIsOpenDialog(false);
                  handleUpdate && handleUpdate({...defaultState, archived: !(defaultState as any)?.archived} as any);
                }}
                onConfirmLabel={formatMessage(globalMessages.yes)}
                onCancel={() => setIsOpenDialog(false)}
                onCancelLabel={formatMessage(globalMessages.no)}
              />
            </Grid>
          }
          {
            mode === 'Edit' && entityName === 'Exercise' &&
            <Grid item>
              {
                (defaultState as any)?.archived &&
                <Button variant="outlined" onClick={() => setIsOpenDialog(true)} disabled={disabled} className={classes.right}>
                  {formatMessage(globalMessages.unarchive)}
                </Button>
              }

              {
                !(defaultState as any)?.archived &&
                <Button variant="outlined" onClick={() => setIsOpenDialog(true)} disabled={disabled} className={classes.right}>
                  {formatMessage(globalMessages.archive)}
                </Button>
              }
              <SimpleDialog
                title={(defaultState as any)?.archived ?
                  formatMessage(entityMessages.unarchive_exercise) : formatMessage(entityMessages.archive_exercise)
                }
                text={(defaultState as any)?.archived ?
                  formatMessage(entityMessages.unarchive_exercise_question) : formatMessage(entityMessages.archive_exercise_question)
                }
                open={isOpenDialog}
                onClose={() => setIsOpenDialog(false)}
                onConfirm={() => {
                  setIsOpenDialog(false);
                  handleUpdate && handleUpdate({...defaultState, archived: !(defaultState as any)?.archived} as any);
                }}
                onConfirmLabel={formatMessage(globalMessages.yes)}
                onCancel={() => setIsOpenDialog(false)}
                onCancelLabel={formatMessage(globalMessages.no)}
              />
            </Grid>
          }
        </Grid>
        {
          formErrorMessage &&
          <Alert severity='error' style={{marginTop: 24}}>
            {formErrorMessage}
          </Alert>
        }
      </Box>
    </Box>
  );
}

export default EntityForm;
