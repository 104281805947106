import React, { Fragment, useState } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import HelpIcon from '@material-ui/icons/Help';
import { useMfwThemeProvider } from "myfitworld-utils";
import HelpSectionDrawer from "./HelpSectionDrawer";

const useStyles = makeStyles(() =>
  createStyles({
    helpIcon: {
      color: "white",
    },
  })
);
const HelpSection = () => {
  const classes = useStyles();
  const theme = useMfwThemeProvider();
  
  const [isOpen, setOpen] = useState(false);
  
  const handleInformationSection = () => {
    setOpen((prevState) => !prevState);
  };
  
  return (
    <Fragment>
      <IconButton onClick={handleInformationSection}>
        <HelpIcon style={{color:theme.mfwMuiWebTheme.palette.text.primary}} className={classes.helpIcon}/>
      </IconButton>
      
      {isOpen && <HelpSectionDrawer isOpen={isOpen} onClose={handleInformationSection}/>}
    </Fragment>
  );
};

export default HelpSection;
