import {  defineMessages } from 'react-intl'

const messages = defineMessages({
    access_is_invitation_only: {
        id: 'access_is_invitation_only',
        defaultMessage: 'Access is invitation only, please check with your admin to share you the invitation'
    },
    show_wl_originals_filter_title: {
        id: 'messages.switches.show_wl_originals_filter_title',
        defaultMessage: '{name} Content'
    },
    org_content_title: {
        id: 'messages.switches.org_content_title',
        defaultMessage: 'Organization Content'
    },
    premium_reports: {
        id: 'premium_reports',
        defaultMessage: 'Premium Reports'
    },
    premium_code: {
        id: 'premium_code',
        defaultMessage: 'Premium Code'
    },
    premium_subscription_type: {
        id: 'premium_subscription_type',
        defaultMessage: 'Subscription Type'
    },
    from: {
        id: 'statistics.from',
        defaultMessage: 'FROM'
    },
    to: {
        id: 'statistics.to',
        defaultMessage: 'TO'
    },
    search: {
        id: 'statistics.search',
        defaultMessage: 'SEARCH'
    },
    monthly: {
        id: 'subscription.monthly',
        defaultMessage: 'MONTHLY'
    },
    yearly: {
        id: 'subscription.yearly',
        defaultMessage: 'YEARLY'
    },
    active: {
        id: 'premium.active',
        defaultMessage: 'ACTIVE'
    },
    unactive: {
        id: 'premium.unactive',
        defaultMessage: 'UNACTIVE'
    },
    type: {
        id: 'organization.type',
        defaultMessage: 'Type'
    },
  rebrand_discount_badge_title: {
    id: 'rebrand.rebrand_discount_badge_title',
    defaultMessage: 'Billing & Discounts:'
  },
  rebrand_discount_badge_text1: {
    id: 'rebrand.rebrand_discount_badge_text1',
    defaultMessage: `You are currently on the MFW FREE plan. The first 2 Clients are $$FREE$$, with NO time limitations. Here are the PRICING dynamics for $$Standard$$, and $$Rebrand$$ users:\n`
  },
  rebrand_discount_badge_free: {
    id: 'rebrand.rebrand_discount_badge_free',
    defaultMessage: 'FREE'
  },
  rebrand_discount_badge_standard: {
    id: 'rebrand.rebrand_discount_badge_standard',
    defaultMessage: 'Standard'
  },
  rebrand_discount_badge_rebrand: {
    id: 'rebrand.rebrand_discount_badge_rebrand',
    defaultMessage: 'Rebrand*'
  },
  rebrand_standard_monthly_price: {
    id: 'rebrand.rebrand_standard_monthly_price',
    defaultMessage: '$$Standard$$ (monthly prices):\n'
  },
  rebrand_rebrand_monthly_price: {
    id: 'rebrand.rebrand_rebrand_monthly_price',
    defaultMessage: '$$Rebrand*$$ (monthly prices):\n'
  },
  rebrand_stripe_payment: {
    id: 'rebrand.rebrand_stripe_payment',
    defaultMessage: '* We use Stripe as a payment processor, a leader among payment processors, worldwide. Pricing is prorated daily, which means that you are charged just for the clients that are in the $$Active$$ and $$Pending lists$$. You are not charged for the  $$Archived$$ Clients. More details in our Terms of Use.\n'
  },
  rebrand_discount_exclusive_pricing: {
    id: 'rebrand.rebrand_discount_exclusive_pricing',
    defaultMessage: 'Here is your $$Re-Brand Exclusive Pricing:$$'
  },
  rebrand_discount_badge_note: {
    id: 'rebrand.rebrand_discount_badge_note',
    defaultMessage: '* To become a $$Rebrand$$ User of the App, please visit your $$Profile$$ Section, follow the steps with explanations and instructions, and enjoy your fully branded MFW Web & Mobile App!\n'
  },
  rebrand_discount_badge_0_2: {
    id: 'rebrand.rebrand_discount_badge_0_2',
    defaultMessage: '0-2 clients: $$FREE$$, always. Even when you go over 3 clients, the first 2 will be free of charge.'
  },
  rebrand_discount_badge_3_14: {
    id: 'rebrand.rebrand_discount_badge_3_14',
    defaultMessage: '3-14 clients:  2.4 EUR / Client / Monthly  -20%'
  },
  rebrand_discount_badge_15_29: {
    id: 'rebrand.rebrand_discount_badge_15_29',
    defaultMessage: '15-29 clients: 2.1 EUR / Client / Monthly -30%'
  },
  rebrand_discount_badge_30_49: {
    id: 'rebrand.rebrand_discount_badge_30_49',
    defaultMessage: '30-49 clients: 1.5 EUR / Client / Monthly -50%'
  },
  rebrand_discount_badge_50_99: {
    id: 'rebrand.rebrand_discount_badge_50_99',
    defaultMessage: '50-99 clients: 1.2 EUR / Client / Monthly -60%'
  },
  rebrand_discount_badge_100: {
    id: 'rebrand.rebrand_discount_badge_100',
    defaultMessage: '100+ clients: 0.9 EUR / Client / Monthly  -70%'
  },
  standard_discount_badge_0_2: {
    id: 'rebrand.standard_discount_badge_0_2',
    defaultMessage: '0-2 clients: FREE, always. Even when you go over 3 clients, the first 2 will be free of charge.'
  },
  standard_discount_badge_3_14: {
    id: 'rebrand.standard_discount_badge_3_14',
    defaultMessage: '3-14 clients: 3 EUR / Client / Monthly'
  },
  standard_discount_badge_15_29: {
    id: 'rebrand.standard_discount_badge_15_29',
    defaultMessage: '15-29 clients: 2.7 EUR / Client / Monthly  -10%'
  },
  standard_discount_badge_30_49: {
    id: 'rebrand.standard_discount_badge_30_49',
    defaultMessage: '30-49 clients: 2.4 EUR / Client / Monthly  -20%'
  },
  standard_discount_badge_50_99: {
    id: 'rebrand.standard_discount_badge_50_99',
    defaultMessage: '50-99 clients: 2.1 EUR / Client / Monthly  -30%'
  },
  standard_discount_badge_100: {
    id: 'rebrand.standard_discount_badge_100',
    defaultMessage: '100+ clients: 1.5 EUR / Client / Monthly   -50%'
  },
  rebrand_pricing_details: {
    id: 'rebrand.rebrand_pricing_details',
    defaultMessage: 'MFW Pricing Details:'
  },
  rebrand_pricing_explanation: {
    id: 'rebrand.rebrand_pricing_explanation',
    defaultMessage: 'The first 2 Clients are $$FREE$$, with NO time limitations. Here are the PRICING dynamics for $$Standard$$, and $$Rebrand$$ users:'
  },
  rebrand_current_billing_discount: {
    id: 'rebrand.rebrand_current_billing_discount',
    defaultMessage: 'Your current billing discount is: '
  },
  rebrand_please_note: {
    id: 'rebrand.rebrand_please_note',
    defaultMessage: 'Please note that the discounts are related to our standard 3 EUR / Client / Monthly. '
  },
  rebrand_prices_applied: {
    id: 'rebrand.rebrand_prices_applied',
    defaultMessage: 'Prices are applied after onboarding the 3rd client, and further. '
  },
  rebrand_if_when_cancel_rebrand: {
    id: 'rebrand.rebrand_if_when_cancel_rebrand',
    defaultMessage: 'If / When you cancel the Rebrand Subscription, $$Standard$$ Pricing will be applied automatically:'
  },
  rebrand_purchase_lifetime_rebrand: {
    id: 'rebrand.rebrand_purchase_lifetime_rebrand',
    defaultMessage: 'If you purchase $$LIFETIME REBRAND, Rebrand$$ Exclusive Pricing will be applied for a lifetime.'
  },
  rebrand_manage_rebrand_subscription: {
    id: 'rebrand.rebrand_manage_rebrand_subscription',
    defaultMessage: `You can manage your $$Rebrand$$ subscription under your $$Profile$$ Section.`
  },
  rebrand_clients: {
    id: 'rebrand.rebrand_clients',
    defaultMessage: `Clients`
  },
  rebrand_no_discount: {
    id: 'rebrand.rebrand_no_discount',
    defaultMessage: 'No Discount'  
  },
  my_schedule: {
    id: 'My Schedule',
    defaultMessage: 'My Schedule'
  },
  schedule_info_text1: {
    id: 'schedule.schedule_info_text1',
    defaultMessage: 'This section shows the list of all the workouts that are scheduled for a certain day.'
  },
  schedule_info_text2: {
    id: 'schedule.schedule_info_text2',
    defaultMessage: 'It also allows you to efficiently manage and organize your clients\' workout schedules. You can easily reschedule time & day of a client’s workout, mark as done, preview, edit, or remove workout sessions for each client.'
  },
  schedule_info_text3: {
    id: 'schedule.schedule_info_text3',
    defaultMessage: 'Additionally, you can communicate directly with your clients by sending messages from this section, ensuring smooth and timely interactions with your clients.'
  },
  schedule_calendly_link_text: {
    id: 'schedule.schedule_calendly_link_text',
    defaultMessage: 'If you need any help on this Section, feel free to schedule a free 20min DEMO at:'
  },
  content: {
    id: 'Content',
    defaultMessage: 'Library'
  },
  libraryInfoText1: {
    id : "library.libraryInfoText1",
    defaultMessage: "In this section, you create, and manage all your exercises, workouts, and programs. It includes access to a library of over 3,500 MFW exercises, 1000 workout templates, 200 program templates (under the switch ,,Include MYFITWORLD Content“), with over 100 filter combinations, making it easy to find and create precisely what you need. All MFW Content can be edited and used as your own content."
  },
  libraryInfoText2: {
    id : "library.libraryInfoText2",
    defaultMessage: "You can also create and organize your own content (exercises, workouts and programs), including uploading videos from YouTube."
  },
  libraryInfoText3: {
    id : "library.libraryInfoText3",
    defaultMessage: "Once you create your Content, you can copy and edit new content from the original one, so the multiplication is fast and easy."
  },
  libraryInfoText4: {
    id : "library.libraryInfoText4",
    defaultMessage: "You can mix MFW, YouTube and your own content however you want."
  },
  nutrition: {
    id: 'nutrition',
    defaultMessage: 'Nutrition'
  },
  nutritionInfoText1: {
    id: 'nutritionInfoText1',
    defaultMessage: 'In this section, you can create, store, and edit  groceries, meals and nutrition plans for your clients. You’ll have access to a free MFW database of groceries and meals, to help you build personalized nutrition plans.'
  },
  nutritionInfoText2: {
    id: 'nutritionInfoText2',
    defaultMessage: 'You can also upload your own custom nutrition plans, meals, and groceries.'
  },
  nutritionInfoText3: {
    id: 'nutritionInfoText3',
    defaultMessage: 'This section ensures that you can offer comprehensive fitness solutions by integrating tailored nutrition plans alongside your workout programs, enhancing your clients\' overall progress and results.'
  },
  clients: {
    id: 'clients',
    defaultMessage: 'Clients'
  },
  clientsInfoText1: {
    id: 'clientsInfoText1',
    defaultMessage: 'This section is where you can manage all your clients in one place.'
  },
  clientsInfoText2: {
    id: 'clientsInfoText2',
    defaultMessage: 'You can view detailed profiles for each client, including their progress photos, body composition, documents attached, personal data, workout history, scheduling history for workouts, programs and meals, or any specific notes or preferences made by you (and visible only to you).'
  },
  clientsInfoText3: {
    id: 'clientsInfoText3',
    defaultMessage: 'This section allows you to track individual performance, manage their workout plans, nutrition plans, and communicate directly with them through the App.'
  },
  clientsInfoText4: {
    id: 'clientsInfoText4',
    defaultMessage: 'You can also go directly into Notification center and Client Groups from this Section, in order to have detailed client status management and communication.'
  },
  clientsInfoText5: {
    id: 'clientsInfoText5',
    defaultMessage: 'This Section simplifies and improves your client CRM and workout planning by giving you quick access to all the necessary actions and tools that will keep your clients engaged and progressing toward their goals.'
  },
  profile: {
    id: 'profile',
    defaultMessage: 'My Profile'
  },
  profileInfoText1: {
    id: 'profileInfoText1',
    defaultMessage: 'In this section, you can edit and personalize how your profile appears on MFWNet and MFW Marketplace, choose the units in which different data will appear on your and your clients’ app (imperial or metric), manage account password, and go straight into the notification center.'
  },
  profileInfoText2: {
    id: 'profileInfoText2',
    defaultMessage: 'You also have the ability to fully customize the Application in the terms of Branding: all the Web Apps for you and other Trainers that belong to this Organization, but also for all the Clients that are under this Organization – for more information, just click on the colored ,,REBRAND“ button and follow the description / steps.'
  },
  configuration: {
    id: 'configuration',
    defaultMessage: 'Configuration'
  },
  configurationInfoText1: {
    id: 'configurationInfoText1',
    defaultMessage: 'In this section, you can manage all the tags, Content specifications and filters on MFW, for everzthing that you create and/or edit on your account.'
  },
  configurationInfoText2: {
    id: 'configurationInfoText2',
    defaultMessage: 'You can add any desired parameter to one of our predefined list, personalizing workout experience for you and your clients to the maximum.'
  },
  configurationInfoText3: {
    id: 'configurationInfoText3',
    defaultMessage: 'Once the newly created parameter is saved, it is ready for an excslusive usage on MFW for you and your Clients.'
  },
  configurationInfoText4: {
    id: 'configurationInfoText4',
    defaultMessage: 'If you wish to be guided for this Section, or you want to permanently remove any added parameter, feel free to contact our support at:'
  },
  myOrganization: {
    id: 'my_organization',
    defaultMessage: "My Organization"
  },
  myOrganizationInfoTextSubTitle: {
    id: 'myOrganizationInfoTextSubTitle',
    defaultMessage: 'Customize Your Organization\'s Profile'
  },
  myOrganizationInfoText: {
    id: 'my_organization_info_text',
    defaultMessage: "In this section, you can easily add new members to your account, whether they are admins, coaches, or assistant coaches. Each role comes with specific permissions and responsibilities:"
  },
  myOrganizationInfoTextList1: {
    id: 'myOrganizationInfoTextList1',
    defaultMessage: "In this section, you can easily add new members to your account, and to give them roles such as Admins, Trainers, or Assistant Trainers."
  },
  myOrganizationInfoTextList2: {
    id: 'myOrganizationInfoTextList2',
    defaultMessage: "Each role comes with specific permissions and responsibilities:"
  },
  myOrganizationInfoTextList3: {
    id: 'myOrganizationInfoTextList3',
    defaultMessage: "$$Admin$$: This role has full access to the account, with the ability to manage everything, including adding members, acting as a Trainer, viewing the activities of other members in this Organization, and overseeing the organization."
  },
  myOrganizationInfoTextList4: {
    id: 'myOrganizationInfoTextList4',
    defaultMessage: "$$Trainer$$: Trainers can manage their own Clients and Content. If granted permission by an admin, they can also share their content, such as exercises, workouts, programs and nutrition with the organization."
  },
  myOrganizationInfoTextList5: {
    id: 'myOrganizationInfoTextList5',
    defaultMessage: "$$Assistant Coach$$: Assistants have a view-only role. They can observe ongoing client sessions and activities but cannot make changes or add new information. This role is often useful for those assisting during practice sessions, or the new members of the team, which need to have some practise before fully entering the workout and clients organization and execution."
  },
  myOrganizationInfoTextList6: {
    id: 'myOrganizationInfoTextList6',
    defaultMessage: "IMPORTANT NOTE: You DO NOT create or invite clients from this section."
  },
  organizationProfile: {
    id: 'organizationProfile',
    defaultMessage: "Organization Profile"
  },
  organizationProfileInfoText1: {
    id: 'organizationProfileInfoText1',
    defaultMessage: "This feature is available for accounts with more than one member in the organization."
  },
  organizationProfileInfoText2: {
    id: 'organizationProfileInfoText2',
    defaultMessage: "Here, you can manage how your organization is presented on MFWNet, MFWMarketplace, and on your clients’ App."
  },
  organizationProfileInfoText3: {
    id: 'organizationProfileInfoText3',
    defaultMessage: "In this Section, you have the ability to fully customize the Application in the terms of Branding: all the Web Apps for you and other Trainers that belong to this Organization, but also for all the Clients that are under this Organization – for more information, just click on the colored ,,REBRAND“ button and follow the description / steps."
  },
  organizationProfileInfoText4: {
    id: 'organizationProfileInfoText4',
    defaultMessage: "You can also view and change permissions for the MFWNet Content, for all the Trainers belonging to this Organization."
  },
  tutorialVideos: {
    id: 'tutorialVideos',
    defaultMessage: "Tutorial Videos"
  },
  tutorialVideosInfoText1: {
    id: 'tutorialVideosInfoText1',
    defaultMessage: "In this section, you will find video explanations (audio and subtitle included) for every Section on MFW App, but also for some specific actions that are taken on a daily basis."
  },
  tutorialVideosInfoText2: {
    id: 'tutorialVideosInfoText2',
    defaultMessage: "Each tutorial lasts between 1-1.5 minutes. These videos provide quick and clear explanations of all platform functionalities, helping you easily navigate and make the most of everything MyFitWorld has to offer."
  },
  tutorialVideosInfoText3: {
    id: 'tutorialVideosInfoText3',
    defaultMessage: "Whether you're looking to set up your profile, manage clients, or explore advanced features, these tutorials guide you step-by-step for a smooth user experience."
  },
  billing: {
    id: 'billing',
    defaultMessage: "Billing"
  },
  billingInfoText1: {
    id: 'billingInfoText1',
    defaultMessage: "In this section, you can manage all aspects of your account's billing and payments."
  },
  billingInfoText2: {
    id: 'billingInfoText2',
    defaultMessage: "Review your current subscription plan, track payment history, and update your payment methods."
  },
  billingInfoText3: {
    id: 'billingInfoText3',
    defaultMessage: "You’ll also find detailed information about your invoices, ensuring full transparency and control over your account’s financials."
  },
  billingInfoText4: {
    id: 'billingInfoText4',
    defaultMessage: "We use ,,Stripe“ as a payment processor – the world’s largest and most credible payment processor."
  },
  billingInfoText5: {
    id: 'billingInfoText5',
    defaultMessage: "Every client’s slot is prorated on a daily basis, which means that we divide (for example)  3EUR on 30 days, and each day your Client is active in the Clients List, it is charged 0.1EUR. This way you get charged only for the active clients, and if by any mean you Archive, or permanently delete/disconnect from your client – the subscription for that client stops, and Stripe does the calculation based on the example mentioned above."
  },
  billingInfoText6: {
    id: 'billingInfoText6',
    defaultMessage: "Our billing methodology is the most competitive one in this industry, globally. That is why MFW does not have a Group-Slots based subscriptions, but you pay as you use."
  },
  billingInfoText7: {
    id: 'billingInfoText7',
    defaultMessage: "We are proud to have the most transparent, the most user-friendly subscription model, so you know how much we care about our members."
  },
  billingInfoText8: {
    id: 'billingInfoText8',
    defaultMessage: "IMPORTANT NOTE: If your card is about to expire, please make sure to enter new billing details (new / alternative bank card) before the subscription cycle renews. If you do not update your billing info on time, all your clients will automatically be Archived. When you update the billing details with a valid card, or stake sufficient funds on your current card, subscription cycles will renew as usual."
  },
  stripeConnect: {
    id: 'stripeConnect',
    defaultMessage: "Stripe Connect"
  },
  stripeConnectInfoText1: {
    id: 'stripeConnectInfoText1',
    defaultMessage: "This feature allows you to link your business directly to $$Stripe Connect$$, enabling you to lock and sell your content, including workouts, workout programs and nutrition plans, through your profile, directly on $$MFW Marketplace$$."
  },
  stripeConnectInfoText2: {
    id: 'stripeConnectInfoText2',
    defaultMessage: "With Stripe Connect Account, you have full control over monetizing your services. Additionally, you can monitor your sales performance through the Stripe Connect dashboard, where you can track what you've sold and who has purchased your content, giving you real-time insights into your business success."
  },
  marketplace: {
    id: 'marketplace',
    defaultMessage: "Marketplace"
  },
  marketplaceInfoText1: {
    id: 'marketplaceInfoText1',
    defaultMessage: "MFW Marketplace is your opportunity to reach your local, or even global audience by selling your workouts, workout programs and nutrition plans directly to clients, without contact!"
  },
  marketplaceInfoText2: {
    id: 'marketplaceInfoText2',
    defaultMessage: "You can showcase your expertise and create additional , passive income streams by offering your content to users worldwide."
  },
  marketplaceInfoText3: {
    id: 'marketplaceInfoText3',
    defaultMessage: "Each piece of content you upload comes with a unique link, which you can promote outside of our Marketplace as well – on your Social Media Accounts, Website…. – Wherever you want!"
  },
  marketplaceInfoText4: {
    id: 'marketplaceInfoText4',
    defaultMessage: "This means you can advertise your content on platforms like Facebook, Instagram, TikTok, Linkedin, your website, and more, allowing potential clients to purchase directly through that link, without the need to get in touch with them directly."
  },
  marketplaceInfoText5: {
    id: 'marketplaceInfoText5',
    defaultMessage: "With the integration of $$Stripe Connect$$, all transactions are secure, and you can easily manage your sales. This gives you full control over your products and pricing, expanding your business on a global scale."
  },
  marketplaceInfoText6: {
    id: 'marketplaceInfoText6',
    defaultMessage: "Marketplace is your Online Store where others go and buy your products and services, while you do whatever you want to."
  },
})

export default messages;
